import {
  Alert,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  query,
  limit,
  orderBy,
  where
} from 'firebase/firestore';
import './UsersRoute.css';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { useState } from 'react';
import Loader from '../components/Loader';
import { UserModel } from '../models/database';

function UsersRoute() {
  const [users] = useCollectionDataOnce(query(UserModel.parent, where('invoiceMtx', '>', 3), orderBy('invoiceMtx', 'desc'), limit(1000)));
  const [showMsg, setShowMsg] = useState({} as {
    type: 'success' | 'warning' | 'info' | 'error';
    message: string;
    isShown: boolean;
  });

  return (!users) ? <Loader></Loader> : (
    <div className='UsersRoute'>
      {(users.length === 0) ? null :
        <TableContainer component={Paper} sx={{ marginBottom: '5em' }}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">ID</TableCell>
                <TableCell align="left">EMAIL</TableCell>
                <TableCell align="left">INVOICES</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                users?.map((e) =>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={`users_table_row_${e?.id}`}>
                    <TableCell align="left" key={`users_table_row_${e?.id}_cell_id`}>{e.id}</TableCell>
                    <TableCell align="left" key={`users_table_row_${e?.id}_cell_email`}>{e.email}</TableCell>
                    <TableCell align="left" key={`users_table_row_${e?.id}_cell_invoices`}>{e.invoiceMtx - 1}</TableCell>
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
      }
      <Snackbar open={showMsg.isShown} autoHideDuration={6000} onClose={() => {
        setShowMsg({ ...showMsg, isShown: false });
      }} sx={{ width: '100%', padding: '3vmin', justifyContent: 'end' }}>
        {
          <Alert onClose={() => {
            setShowMsg({ ...showMsg, isShown: false });
          }} sx={{ marginRight: '3vmin' }} severity={showMsg.type}>{showMsg.message}</Alert>
        }
      </Snackbar>
    </div>
  );
}

export default UsersRoute;