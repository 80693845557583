import { useState, FormEvent } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { signInWithEmailAndPassword } from 'firebase/auth';
import FirebaseAuth, { errorMessagesMap } from '../services/FirebaseAuth';
import { Alert, Snackbar } from '@mui/material';
import { FirebaseError } from 'firebase/app';
import './SignIn.css';
import SignInWithButton, { SignInProviders } from './SignInWithButton';

export default function SignIn({
  errMessage
}: {
  errMessage?: string;
}) {
  const [errorMessage, setErrorMessage] = useState('');

  const [showMsg, setShowMsg] = useState({} as {
    type: 'success' | 'warning' | 'info' | 'error';
    message: string;
    isShown: boolean;
  });

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    localStorage.removeItem('authorized');
    signInWithEmailAndPassword(
      FirebaseAuth,
      data.get('email')?.toString() || '',
      data.get('password')?.toString() || 'none'
    ).then(() => {
      setErrorMessage('');
    }).catch((err: FirebaseError) => {
      switch (err.code) {
        case 'auth/user-not-found':
        case 'auth/wrong-password':
          setErrorMessage('Invalid username or password');
          break;
        default:
          if (errorMessagesMap[err.code]) {
            setErrorMessage(errorMessagesMap[err.code]);
          } else {
            setErrorMessage('Unknown error');
          }
          break;
      }
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {(errorMessage || errMessage) ? <Alert severity="error">{errorMessage || errMessage}</Alert> : null}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3 }}
          >
            Sign In
          </Button>
          <Grid container textAlign={'center'}>
            {
              Object.keys(SignInProviders).map((e: string) =>
                <Grid item xs key={`signin_button_${e.toString()}`}>
                  <SignInWithButton provider={e as SignInProviders} errorHandler={(err: any) => {
                    if (errorMessagesMap[err.code]) {
                      setShowMsg({
                        type: 'error',
                        message: errorMessagesMap[err.code],
                        isShown: true,
                      });
                    } else {
                      setShowMsg({
                        type: 'error',
                        message: 'Unknown error',
                        isShown: true,
                      });
                    }
                  }}></SignInWithButton>
                </Grid>
              )
            }
          </Grid>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs>
              <Link href="https://miniaccountant.app/password_recovery" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="https://miniaccountant.app" variant="body2">
                {"Don't have an account?"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Snackbar open={showMsg.isShown} autoHideDuration={6000} onClose={() => {
        setShowMsg({ ...showMsg, isShown: false });
      }} sx={{ width: '100%', padding: '3vmin', justifyContent: 'end' }}>
        {
          <Alert onClose={() => {
            setShowMsg({ ...showMsg, isShown: false });
          }} sx={{ marginRight: '3vmin' }} severity={showMsg.type}>{showMsg.message}</Alert>
        }
      </Snackbar>
    </Container>
  );
}