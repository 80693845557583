import moment, { Moment } from 'moment-timezone';
import {
  DocumentReference,
  QueryDocumentSnapshot,
  DocumentSnapshot,
  Transaction,
  Timestamp,
  collection,
  doc,
  getDoc,
  setDoc,
} from 'firebase/firestore';
import FirebaseFirestore from '../../../services/FirebaseFirestore';
import { Currency } from '../Currency';
import { generateIdFromString } from '../../../utils/generateIdFromString';

export enum ResourceEnum {
  none = 'none',
  nationalBankOfGeorgia = 'nbg.gov.ge',
  yahooFinance = 'finance.yahoo.com',
  fmpCloud = 'fmpcloud.io',
}

export class ExchangeRateModel {
  constructor({
    id,
    indexKey,
    date,
    origDate,
    from,
    to,
    rate,
    info,
    loadedAt,
    loadedFrom,
    metadata,
  }: {
    id?: string;
    indexKey?: string;
    date: Moment;
    origDate: Moment;
    from: Currency,
    to: Currency;
    rate: number;
    info: string;
    loadedAt: Moment;
    loadedFrom: ResourceEnum;
    metadata: { [key: string]: any };
  }) {
    this.id = typeof id === 'string' ?
      id : ExchangeRateModel.generateId(date, from, to, loadedFrom);
    this.indexKey = typeof indexKey === 'string' ?
      indexKey : ExchangeRateModel.generateIndexKey(date, from, to, loadedFrom);
    this.date = date;
    this.origDate = origDate;
    this.from = from;
    this.to = to;
    this.rate = rate;
    this.info = info;
    this.loadedAt = loadedAt;
    this.loadedFrom = loadedFrom;
    this.metadata = metadata;
  }

  id?: string;

  indexKey: string;

  date: Moment;

  origDate: Moment;

  from: Currency;

  to: Currency;

  rate: number;

  info: string;

  loadedAt: Moment;

  loadedFrom: ResourceEnum;

  metadata: { [key: string]: any }

  static generateIndexKey = (
    date: Moment,
    from: Currency,
    to: Currency,
    loadedFrom: ResourceEnum
  ): string => `${date.tz('UTC').startOf('d').format('YYYY-MM-DD')}:${from.toString()}:${to.toString()}:${loadedFrom.toString()}`;

  static generateId = (
    date: Moment,
    from: Currency,
    to: Currency,
    loadedFrom: ResourceEnum
  ): string =>
    generateIdFromString(ExchangeRateModel.generateIndexKey(date, from, to, loadedFrom));

  private static resourceKeys = Object.keys(ResourceEnum) as (keyof typeof ResourceEnum)[];

  private static getResourceEnum(name: string) {
    for (const key of ExchangeRateModel.resourceKeys)
      if (ResourceEnum[key].toString() === name) return ResourceEnum[key];
    return ResourceEnum.none;
  }

  static fromJson(id: string, json: { [key: string]: any }): ExchangeRateModel {
    return new ExchangeRateModel({
      id: id,
      indexKey: typeof json?.indexKey === 'string' ? json.indexKey : undefined,
      date: json?.date instanceof Timestamp ?
        moment(json.date.toMillis()).tz('UTC').startOf('day') : moment().tz('UTC').startOf('day'),
      origDate: json?.origDate instanceof Timestamp ?
        moment(json.origDate.toMillis()).tz('UTC').startOf('day') : moment().tz('UTC').startOf('day'),
      from: typeof json?.from === 'string'
        ? Currency.fromString(json.from)
        : Currency.def,
      to: typeof json?.to === 'string'
        ? Currency.fromString(json.to)
        : Currency.def,
      rate: typeof json?.rate === 'number' ? json.rate : 0.0,
      info: typeof json?.info === 'string' ? json.info : '',
      loadedAt: json?.loadedAt instanceof Timestamp ?
        moment(json.loadedAt.toMillis()).tz('UTC') : moment().tz('UTC').startOf('day'),
      loadedFrom: ExchangeRateModel.getResourceEnum(json?.loadedFrom),
      metadata: typeof json?.metadata === 'object' ? json.metadata : {},
    });
  }

  toJson(): { [key: string]: any } {
    return {
      indexKey: this.indexKey,
      date: Timestamp.fromMillis(this.date.tz('UTC').startOf('day').valueOf()),
      origDate: Timestamp.fromMillis(this.origDate.tz('UTC').startOf('day').valueOf()),
      from: this.from.toString(),
      to: this.to.toString(),
      rate: this.rate,
      info: this.info,
      loadedAt: Timestamp.fromMillis(this.loadedAt.valueOf()),
      loadedFrom: this.loadedFrom.toString(),
      metadata: this.metadata,
    };
  }

  static parent = collection(FirebaseFirestore,
    'exchangeRates'
  ).withConverter<ExchangeRateModel>({
    toFirestore: (doc: ExchangeRateModel) => doc.toJson(),
    fromFirestore: (snapshot: QueryDocumentSnapshot) =>
      ExchangeRateModel.fromJson(snapshot.id, snapshot.data()),
  });

  static withId = (id: string): Promise<DocumentSnapshot<ExchangeRateModel>> =>
    getDoc(doc(ExchangeRateModel.parent, id));

  ref = (): DocumentReference<ExchangeRateModel> =>
    doc(ExchangeRateModel.parent, typeof this.id === 'string' ?
      this.id : ExchangeRateModel.generateId(this.date, this.from, this.to, this.loadedFrom)
    );

  load = (
    transaction?: Transaction
  ): Promise<DocumentSnapshot<ExchangeRateModel>> =>
    transaction instanceof Transaction
      ? transaction.get(this.ref())
      : getDoc(this.ref());

  save = (transaction?: Transaction): Promise<void | Transaction> =>
    transaction instanceof Transaction
      ? Promise.resolve(transaction.set(this.ref(), this))
      : setDoc(this.ref(), this);
}
