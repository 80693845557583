import { openDB, DBSchema, IDBPDatabase } from 'idb';

const shemaVersion = 1;
interface CacheDB extends DBSchema {
  authUsers: {
    value: {
      id: string;
      email?: string;
      emailVerified: boolean;
    };
    key: string;
    indexes: {};
  };
  metadata: {
    value: {
      timestamp: Date;
    },
    key: 'authUsers';
  };
}

export let db: IDBPDatabase<CacheDB> | undefined = undefined;

export const getDb = async (): Promise<IDBPDatabase<CacheDB>> => {
  if (db) return db;
  db = await openDB<CacheDB>('admin.miniaccountant.app/cache', shemaVersion, {
    async upgrade(db, oldVersion) {
      const v1Db = db as unknown as IDBPDatabase<CacheDB>;
      if (oldVersion < 1) {
        v1Db.createObjectStore('authUsers');
        v1Db.createObjectStore('metadata');
      }
    },
  });
  return db;
}