import { Outlet, Route, Routes } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { NoMatchRoute } from '../routes';
import FirebaseAuth from '../services/FirebaseAuth';
import Loader from '../components/Loader';
import TopBar, { menuItems } from '../components/TopBar';
import SignIn from '../components/SignIn';
import { Link, Paper, Typography } from '@mui/material';
import './BaseScreen.css';
import FirebaseFirestore from '../services/FirebaseFirestore';
import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';

export default function BaseScreen() {
  const [user, loading] = useAuthState(FirebaseAuth);
  const [isAdmin, setIsAdmin] = useState(false);
  const [errMessage, setErrorMessage] = useState('');
  useEffect(() => {
    if (user) {
      const admRef = doc(FirebaseFirestore, 'admins', user?.uid);
      getDoc(admRef).then((e) => e.exists() ?
        Promise.resolve() : Promise.reject()
      ).then(() => {
        setIsAdmin(true);
        setErrorMessage('');
      }).catch((e) => {
        setIsAdmin(false);
        setErrorMessage('Your user doesn\'t have enough rights.');
        FirebaseAuth.signOut().catch((e) => { });
      });
    } else {
      setIsAdmin(false);
    }
  }, [user]);

  return (
    <div className="App">
      <TopBar />
      {
        (loading) ? <Loader /> :
          (isAdmin) ?
            <Routes>
              <Route path="/" element={<Outlet />} key="page_main">
                {
                  menuItems.map((e, i) => {
                    if (i === 0) {
                      return <Route index element={e.component()} key={e.key} />;
                    }
                    return <Route path={e.path.substring(1)} element={e.component()} key={e.key} />;
                  })
                }
                <Route path="*" element={<NoMatchRoute />} key="page_notfound" />
              </Route>
            </Routes>
            : <Routes>
              <Route path="/" element={<SignIn errMessage={errMessage} />} key="page_signin"></Route>
              <Route path="*" element={<SignIn errMessage={errMessage} />} key="page_signin"></Route>
            </Routes>
      }
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <Typography align='center'><Link href="https://dudko.dev" underline='none'>2022-{new Date().getFullYear()}©by siarhei dudko</Link></Typography>
      </Paper>
    </div >
  );
}