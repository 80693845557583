import { ReactElement, cloneElement, SyntheticEvent, useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Tab, Tabs } from '@mui/material';
import {
  UsersRoute,
  StatisticsRoute,
  SubscriptionsRoute,
} from '../routes';
import FirebaseAuth from '../services/FirebaseAuth';
import { useAuthState } from 'react-firebase-hooks/auth';
import LogoutIcon from '@mui/icons-material/Logout';
import './TopBar.css';
import FirebaseFirestore from '../services/FirebaseFirestore';
import { doc, getDoc } from 'firebase/firestore';

const menuItems: {
  key: string,
  path: string,
  component: () => JSX.Element,
  label: string,
}[] = [
    {
      key: "page_statistics",
      path: "/",
      component: () => <StatisticsRoute />,
      label: "Statistics",
    },
    {
      key: "page_subscriptions",
      path: "/subscriptions",
      component: () => <SubscriptionsRoute />,
      label: "Subscriptions",
    },
    {
      key: "page_users",
      path: "/users",
      component: () => <UsersRoute />,
      label: "Users",
    },

  ];

interface Props {
  window?: () => Window;
  children: ReactElement;
}

function ElevationScroll(props: Props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

function ScrollableTabsButtonAuto() {
  const location = useLocation();
  const pathIndex = menuItems.findIndex((e) => e.path === location.pathname);

  const [pageIndex, setPageIndex] = useState(pathIndex);

  useEffect(() => {
    const i = menuItems.findIndex((e) => e.path === location.pathname)
    if (typeof menuItems[i]?.path === 'string') {
      setPageIndex(i);
    } else {
      setPageIndex(-1);
    }
  }, [location]);

  const navigate = useNavigate();

  const handleChange = (event: SyntheticEvent, newPageIndex: number) => {
    if (typeof menuItems[newPageIndex]?.path === 'string')
      navigate(menuItems[newPageIndex].path);
    else
      navigate('/404');
  };

  return (
    <Box sx={{ bgcolor: 'background.paper' }}>
      <Tabs
        value={(pageIndex !== -1) ? pageIndex : false}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Header Menu"
      >
        {
          menuItems.map((e, i) => <Tab label={e.label} key={`header_menu_${i}`} />)
        }
      </Tabs>
    </Box>
  );
}

export default function ElevateAppBar() {
  const [user] = useAuthState(FirebaseAuth);
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    if (user) {
      const admRef = doc(FirebaseFirestore, 'admins', user?.uid);
      getDoc(admRef).then((e) => {
        if (e.exists()) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
          FirebaseAuth.signOut().catch((e) => { });
        }
      }).catch((e) => {
        setIsAdmin(false);
        FirebaseAuth.signOut().catch((e) => { });
      });
    } else {
      setIsAdmin(false);
    }
  }, [user]);

  return (
    <div>
      <ElevationScroll>
        <AppBar>
          <Toolbar>
            <Box component="img" className="App-logo" sx={{ flexGrow: 0, maxHeight: '4vmin', marginRight: '1vmin' }} src="./logo192.png" alt="logo">
            </Box>
            <Typography variant="h6" component="div">
              Mini Accountant Admin Portal
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, minWidth: '4vmin', justifyContent: 'end' }}>
              {(isAdmin) ? <Typography sx={{ paddingRight: '1vmin' }}>{`${user?.email}`}</Typography> : null}
            </Box>
            {(isAdmin) ? <Box sx={{ flexGrow: { xs: 1, md: 0 }, display: { xs: 'flex', md: 'unset' }, minWidth: '4vmin', justifyContent: { xs: 'end', md: 'unset' } }}>
              <Button variant="outlined" color="secondary" onClick={() => {
                localStorage.removeItem('authorized');
                FirebaseAuth.signOut();
              }}>
                <LogoutIcon />
              </Button>
            </Box> : null}
          </Toolbar>
          {(isAdmin) ? <ScrollableTabsButtonAuto /> : null}
        </AppBar>
      </ElevationScroll>
      <Toolbar />
      <Toolbar />
    </div>
  );
}

export { menuItems };