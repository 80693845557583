import {
  Alert,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment-timezone';
import { Timestamp, doc, query, where } from 'firebase/firestore';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import './SubscriptionsRoute.css';
import { useCallback, useMemo, useState } from 'react';
import Loader from '../components/Loader';
import { SubscriptionModel, UserModel } from '../models/database';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { createSubscription } from '../methods/subscription/create';
import { updateSubscription } from '../methods/subscription/update';
import { deleteSubscription } from '../methods/subscription/delete';
import { SubscriptionType, subscriptionTypes } from '../models/database/SubscriptionType';
import { AutUser, getAuthUsers } from '../methods/getAuthUsers';

const getSubscrStatus = (e: SubscriptionModel) => {
  if (e.validTo.valueOf() === 0)
    return 'DISABLED';
  return e.validTo.valueOf() > Date.now() ? 'ACTIVE' : 'EXPIRED';
}

function SubscriptionsRoute() {
  const subscriptionRef = query(
    SubscriptionModel.parent,
    where('validTo', '!=', Timestamp.fromMillis(0)),
  );

  const [users, setUsers] = useState(undefined as AutUser[] | undefined);
  const [subscriptions] = useCollectionData(subscriptionRef);
  const [editSubscription, setEditSubscription] = useState({
    isOpenDialog: false,
  } as {
    subscription?: SubscriptionModel;
    isOpenDialog: boolean;
  });
  const [showMsg, setShowMsg] = useState({} as {
    type: 'success' | 'warning' | 'info' | 'error';
    message: string;
    isShown: boolean;
  });
  const [paymentInfo, setPaymentInfo] = useState({
    isOpenDialog: false,
  } as {
    json?: SubscriptionModel['paymentInfo'];
    isOpenDialog: boolean;
  });

  const getUsers = useCallback(async () => {
    try {
      if (Array.isArray(users)) return;
      const usrs = await getAuthUsers();
      setUsers(usrs);
    } catch (err: Error | any) {
      setShowMsg({
        type: 'error',
        message: err?.message,
        isShown: true,
      })
    }
  }, [users]);

  getUsers();

  const sortedUsers = useMemo(() => {
    return (users || []).sort((a, b) => {
      if (a.email && b.email) {
        if (a.email === b.email) return 0;
        return a.email > b.email ? 1 : -1;
      }
      if (a.email) return -1;
      if (b.email) return 1;
      if (a.id === b.id) return 0;
      return a.id > b.id ? 1 : -1;
    })
  }, [users]);

  const { mappings, autocompleteReverse, autocompleteTooltip } = useMemo(() => {
    const aReverse: { [key: string]: number } = {};
    const aTooltip: { label: string, userId: string }[] = [];
    const m: {
      users: { [key: string]: AutUser },
      subscriptions: { [key: string]: SubscriptionModel },
    } = { users: {}, subscriptions: {} };
    sortedUsers.forEach((e) => {
      aReverse[e.id] = aTooltip.length;
      aTooltip.push({ label: e.email || e.id, userId: e.id });
    });
    m['users'] = sortedUsers.reduce((pre, e) => {
      pre[e.id] = e;
      return pre;
    }, {} as { [key: string]: AutUser }) || {}
    m['subscriptions'] = subscriptions?.reduce((pre, e) => {
      pre[e.id as string] = e;
      return pre;
    }, {} as { [key: string]: SubscriptionModel }) || {};
    return {
      mappings: m,
      autocompleteReverse: aReverse,
      autocompleteTooltip: aTooltip,
    };
  }, [sortedUsers, subscriptions]);

  const sortedSubscriptions = useMemo(() => {
    return (subscriptions || []).sort((a, b) => {
      if (mappings.users[a.user.id]?.email && mappings.users[b.user.id]?.email) {
        if (mappings.users[a.user.id].email === mappings.users[b.user.id].email) return a.validTo.valueOf() > b.validTo.valueOf() ? 1 : -1;
        return (mappings.users[a.user.id]?.email || '') > (mappings.users[b.user.id]?.email || '') ? 1 : -1;
      }
      if (mappings.users[a.user.id]?.email) return -1;
      if (mappings.users[b.user.id]?.email) return 1;
      if (a.user.id === b.user.id) return a.validTo.valueOf() > b.validTo.valueOf() ? 1 : -1;
      return (a.user.id > b.user.id) ? 1 : -1;
    });
  }, [subscriptions, mappings]);

  const subscr: Partial<SubscriptionModel> = {
    id: editSubscription.subscription?.id,
    type: editSubscription.subscription?.type || SubscriptionType.def,
    description: editSubscription.subscription?.description || '',
    paymentInfo: editSubscription.subscription?.paymentInfo || {},
    validTo: editSubscription.subscription?.validTo || moment().startOf('d').tz('Etc/Utc').add(1, 'd'),
    user: editSubscription.subscription?.user,
  };


  return (!subscriptions || !users) ? <Loader></Loader> : (
    <div className='SubscriptionsRoute'>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Button sx={{ marginLeft: "auto" }} variant="outlined" color="primary" onClick={() => {
          setEditSubscription({ subscription: undefined, isOpenDialog: true });
        }} disabled={false}>
          <AddCircleOutlineOutlinedIcon />
        </Button> </Toolbar>
      {
        (subscriptions?.length === 0) ? null : <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">STATUS</TableCell>
                <TableCell align="center">TYPE</TableCell>
                <TableCell align="center">DESCRIPTION</TableCell>
                <TableCell align="center">PAYMENT INFO</TableCell>
                <TableCell align="center">VALID TO</TableCell>
                <TableCell align="center">USER ID</TableCell>
                <TableCell align="center">USER EMAIL</TableCell>
                <TableCell align="center">EDIT</TableCell>
                <TableCell align="center">REMOVE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                sortedSubscriptions.map((e) =>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={`subscriptions_table_row_${e.id}`}>
                    <TableCell align="center" key={`subscriptions_table_row_${e.id}_cell_id`}>{e.id}</TableCell>
                    <TableCell align="center" key={`subscriptions_table_row_${e.id}_cell_status`}>{getSubscrStatus(e)}</TableCell>
                    <TableCell align="center" key={`subscriptions_table_row_${e.id}_cell_type`}>{e.type.toString()}</TableCell>
                    <TableCell align="center" key={`subscriptions_table_row_${e.id}_cell_description`}>{e.description}</TableCell>
                    <TableCell align="center" key={`subscriptions_table_row_${e.id}_cell_paymentInfo`}>
                      <Button variant="outlined" color="primary" onClick={() => {
                        setPaymentInfo({
                          isOpenDialog: true,
                          json: e.paymentInfo,
                        });
                      }} key={`subscriptions_table_row_${e.id}_cell_paymentInfo_button`}>
                        <VisibilityIcon key={`subscriptions_table_row_${e.id}_cell_paymentInfo_button_icon`} />
                      </Button>
                    </TableCell>
                    <TableCell align="center" key={`subscriptions_table_row_${e.id}_cell_validTo`}>{e.validTo.format('YYYY-MM-DD')}</TableCell>
                    <TableCell align="center" key={`subscriptions_table_row_${e.id}_cell_userId`}>{e.user.id}</TableCell>
                    <TableCell align="center" key={`subscriptions_table_row_${e.id}_cell_userEmail`}>{mappings.users[e.user.id]?.email}</TableCell>
                    <TableCell align="center" key={`subscriptions_table_row_${e.id}_edit`}>
                      <Button variant="outlined" color="primary" onClick={() => {
                        setEditSubscription({ subscription: e, isOpenDialog: true });
                      }} disabled={false} key={`subscriptions_table_row_${e.id}_update_button`}>
                        <EditOutlinedIcon key={`subscriptions_table_row_${e.id}_update_button_icon`} />
                      </Button></TableCell>
                    <TableCell align="center" key={`subscriptions_table_row_${e.id}_delete`}>
                      <Button variant="outlined" color="primary" onClick={() => {
                        deleteSubscription(e).then(() => {
                          setShowMsg({ type: 'success', message: 'Removed!', isShown: true });
                        }).catch((err: Error) => {
                          setShowMsg({ type: 'error', message: err.message || 'Unknown error', isShown: true });
                        });
                      }} disabled={false} key={`subscriptions_table_row_${e.id}_delete_button`}>
                        <DeleteOutlineOutlinedIcon key={`subscriptions_table_row_${e.id}_delete_button_icon`} />
                      </Button></TableCell>
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
      }
      {
        editSubscription.isOpenDialog ? <Dialog open={true}>
          <DialogTitle>{editSubscription.subscription ? 'Edit' : 'Add'} Subscription</DialogTitle>
          <DialogContent>
            <FormControl fullWidth sx={{ marginTop: '1em' }}>
              <InputLabel id="select-subscription-type">Type</InputLabel>
              <Select
                labelId="select-subscription-type"
                id="subscr-select-type"
                value={subscr.type?.name}
                label="Type"
                onChange={(v) => {
                  if (typeof v.target.value === 'string') subscr.type = SubscriptionType.isValidString(v.target.value) ?
                    SubscriptionType.fromString(v.target.value) : SubscriptionType.def;
                }}
              >
                {subscriptionTypes.map((e) => {
                  const subscriptionType = new SubscriptionType({ name: e });
                  return <MenuItem key={`menu_select_subscription_type_${subscriptionType.name}`} value={subscriptionType.name}>{subscriptionType.toString()}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: '1em' }}>
              <TextField
                label="Description"
                defaultValue={subscr.description}
                multiline
                rows={4}
                onChange={(v) => {
                  if (typeof v.target.value === 'string') subscr.description = v.target.value;
                }}
              />
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Valid To"
                sx={{ marginTop: '1em' }}
                defaultValue={subscr.validTo}
                onChange={(v) => {
                  if (moment.isMoment(v)) subscr.validTo = v;
                }}
              />
            </LocalizationProvider>
            <FormControl fullWidth sx={{ marginTop: '1em', marginBottom: '1em' }}>
              <Autocomplete
                disablePortal
                options={autocompleteTooltip}
                renderInput={(params) => <TextField {...params} label="User" />}
                defaultValue={(autocompleteTooltip[autocompleteReverse[subscr.user?.id as any]] || null)}
                isOptionEqualToValue={(option, value) => value.userId === option.userId}
                onChange={(e, v) => {
                  if (v && typeof v.userId === 'string') {
                    subscr.user = doc(UserModel.parent, v.userId);
                  }
                }}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setEditSubscription({ subscription: undefined, isOpenDialog: false });
            }}>Cancel</Button>
            <Button onClick={() => {
              if (!subscr.type) {
                setShowMsg({ type: 'error', message: 'You need chose a type', isShown: true });
                return;
              }
              if (typeof subscr.description !== 'string') {
                setShowMsg({ type: 'error', message: 'You need chose a description', isShown: true });
                return;
              }
              if (!subscr.paymentInfo) {
                setShowMsg({ type: 'error', message: 'You need chose a paymentInfo', isShown: true });
                return;
              }
              if (!subscr.validTo) {
                setShowMsg({ type: 'error', message: 'You need chose a validTo', isShown: true });
                return;
              }
              if (!subscr.user) {
                setShowMsg({ type: 'error', message: 'You need chose a user', isShown: true });
                return;
              }
              const newSubscription: SubscriptionModel = new SubscriptionModel({
                id: subscr.id,
                type: subscr.type,
                description: subscr.description,
                paymentInfo: subscr.paymentInfo,
                validTo: subscr.validTo,
                user: subscr.user,
              });
              if (!editSubscription.subscription) {
                createSubscription(newSubscription).then(() => {
                  setEditSubscription({ subscription: undefined, isOpenDialog: false });
                }).catch((err: Error) => {
                  setShowMsg({ type: 'error', message: err.message || 'Unknown error', isShown: true });
                });
              } else {
                updateSubscription(newSubscription).then(() => {
                  setEditSubscription({ subscription: undefined, isOpenDialog: false });
                }).catch((err: Error) => {
                  setShowMsg({ type: 'error', message: err.message || 'Unknown error', isShown: true });
                });
              }
            }}>Save</Button>
          </DialogActions>
        </Dialog> : null
      }
      {
        paymentInfo.isOpenDialog ? <Dialog
          id="payment-info-dialog"
          open={true}
          onClose={() => { setPaymentInfo({ isOpenDialog: false, json: undefined }); }}>
          <DialogTitle id="payment-info-dialog-title">
            <Typography>Payment Info</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="payment-info-dialog-description" style={{ whiteSpace: 'pre-line' }}>
              {JSON.stringify(paymentInfo.json, undefined, "\u00A0\u00A0\u00A0\u00A0")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={async () => {
              await navigator.clipboard.writeText(JSON.stringify(paymentInfo.json, undefined, '\t')).then(() => {
                setShowMsg({ type: 'success', message: 'Copied!', isShown: true });
              }).catch((err: Error) => {
                setShowMsg({ type: 'error', message: err.message || 'Unknown error', isShown: true });
              });
            }} autoFocus>Copy</Button>
            <Button onClick={() => { setPaymentInfo({ isOpenDialog: false, json: undefined }); }}>
              Close
            </Button>
          </DialogActions>
        </Dialog> : null
      }
      <Snackbar open={showMsg.isShown} autoHideDuration={6000} onClose={() => {
        setShowMsg({ ...showMsg, isShown: false });
      }} sx={{ width: '100%', padding: '3vmin', justifyContent: 'end' }}>
        {
          <Alert onClose={() => {
            setShowMsg({ ...showMsg, isShown: false });
          }} sx={{ marginRight: '3vmin' }} severity={showMsg.type}>{showMsg.message}</Alert>
        }
      </Snackbar>
    </div>
  );
}

export default SubscriptionsRoute;