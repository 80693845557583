import FirebaseAuth from "../services/FirebaseAuth";
import { getDb } from "../services/IndexedDB";

export interface AutUser {
  id: string;
  email?: string;
  emailVerified: boolean;
};

const cacheTimeout = 15 * 60000;
let isCaching = false;

export const getAuthUsers = async (): Promise<AutUser[]> => {
  const dt = new Date();
  const db = await getDb().catch((err) => {
    console.warn('Cache usage is limited', err);
    return;
  });
  const metadata = await db?.get('metadata', 'authUsers').catch((err) => {
    console.warn('Сan\'t load the cache', err);
  });
  const authUsers = await db?.getAll('authUsers').catch((err) => {
    console.warn('Сan\'t load the cache', err);
  });
  if (
    metadata?.timestamp &&
    metadata?.timestamp.valueOf() + cacheTimeout > dt.getTime() &&
    authUsers?.length
  ) return authUsers;
  const token = await FirebaseAuth.currentUser?.getIdToken();
  const response: AutUser[] = await fetch('https://admin.miniaccountant.app/admin/auth_users', {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  }).then((res) => {
    if (res.status !== 200)
      return Promise.reject('Invalid response status');
    return res.json();
  }).then((d) => {
    if (!Array.isArray(d?.users))
      return Promise.reject('Invalid response data');
    return d.users as AutUser[];
  });
  if (db && !isCaching) {
    isCaching = true;
    const authUsersTr = db.transaction('authUsers', 'readwrite');
    const metadataTr = db.transaction('metadata', 'readwrite');
    Promise.all([
      authUsersTr.store.clear(),
      ...response.map((e) => authUsersTr.store.put(e, e.id)),
      metadataTr.store.put({ timestamp: dt }, 'authUsers'),
      authUsersTr.done,
      metadataTr.done,
    ])
      .catch((err) => { console.warn('Cache usage is limited', err); }).finally(() => {
        isCaching = false;
      });
  }
  return response;
}