import {
  Alert,
  CardMedia,
  Grid,
  Snackbar,
} from '@mui/material';
import './StatisticsRoute.css';
import { useCallback, useState } from 'react';
import Loader from '../components/Loader';
import FirebaseAuth from '../services/FirebaseAuth';

function StatisticsRoute() {

  const [idToken, setIdToken] = useState(undefined as string | undefined);

  const [showMsg, setShowMsg] = useState({} as {
    type: 'success' | 'warning' | 'info' | 'error';
    message: string;
    isShown: boolean;
  });

  const updateToken = useCallback(async () => {
    try {
      const token = await FirebaseAuth.currentUser?.getIdToken();
      setIdToken(token);
    } catch (err: Error | any) {
      setShowMsg({
        type: 'error',
        message: err?.message,
        isShown: true,
      })
    }
  }, []);

  updateToken();

  return (!idToken) ? <Loader></Loader> : (
    <Grid container className='StatisticsRoute'>
      <CardMedia component='iframe' key={`admin_dashboard_content_statistics`} title={`Statistics GA`} src={`${process.env.REACT_APP_API_URL}/admin/statistics/page-1?token=${idToken}`} sx={{ border: 0 }}></CardMedia>
      <Snackbar open={showMsg.isShown} autoHideDuration={6000} onClose={() => {
        setShowMsg({ ...showMsg, isShown: false });
      }} sx={{ width: '100%', padding: '3vmin', justifyContent: 'end' }}>
        {
          <Alert onClose={() => {
            setShowMsg({ ...showMsg, isShown: false });
          }} sx={{ marginRight: '3vmin' }} severity={showMsg.type}>{showMsg.message}</Alert>
        }
      </Snackbar>
    </Grid>
  );
}

export default StatisticsRoute;