import {
  DocumentReference,
  QueryDocumentSnapshot,
  DocumentSnapshot,
  Transaction,
  collection,
  doc,
  getDoc,
  setDoc,
} from 'firebase/firestore';
import FirebaseFirestore from '../../../services/FirebaseFirestore';

export class AdminDashboardContentModel {
  constructor({
    statistics = {},
  }: {
    statistics: { [key: string]: string };
  }) {
    this.statistics = statistics;
  }

  static id = 'admin_dashboard_content'

  id = AdminDashboardContentModel.id

  statistics: { [key: string]: string };

  static fromJson(json: { [key: string]: any }): AdminDashboardContentModel {
    return new AdminDashboardContentModel({
      statistics: typeof json?.statistics === 'object' ? Object.keys(json.statistics).reduce((pre, val) => {
        if (typeof json.statistics[val] === 'string') pre[val] = json.statistics[val];
        return pre;
      }, {} as { [key: string]: string }) : {},
    });
  }

  toJson(): { [key: string]: any } {
    return {
      statistics: this.statistics,
    };
  }

  static parent = collection(FirebaseFirestore, '_internal_').withConverter<AdminDashboardContentModel>({
    toFirestore: (doc: AdminDashboardContentModel) => doc.toJson(),
    fromFirestore: (snapshot: QueryDocumentSnapshot) =>
      AdminDashboardContentModel.fromJson(snapshot.data()),
  });

  static ref = doc(this.parent, AdminDashboardContentModel.id);

  ref = (): DocumentReference<AdminDashboardContentModel> => {
    return doc(AdminDashboardContentModel.parent, this.id);
  };

  load = (transaction?: Transaction): Promise<DocumentSnapshot<AdminDashboardContentModel>> =>
    transaction instanceof Transaction
      ? transaction.get(this.ref())
      : getDoc(this.ref());

  save = (transaction?: Transaction): Promise<void | Transaction> =>
    transaction instanceof Transaction
      ? Promise.resolve(transaction.set(this.ref(), this))
      : setDoc(this.ref(), this);
}
